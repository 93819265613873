import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 스테이지파이브ㆍ키위플러스ㆍ지아이바이옴ㆍ지아이비타 업무협약 체결",
  "| “AIoT 기반 유ㆍ아동 스마트 보건 사업, 개인별 맞춤의학 미래 열 것”",
]

const content = () => (
  <div>
    <style>
      {
        "\
          .pressDetailPage .pressDetailBody .contentArea .descImage img {\
            margin: auto;\
            width: 330px;\
          }\
        "
      }
    </style>
    <div className="descImage promotion">
      <div className="img">
        <img src="/images/press/8/VvicmHuW.jpg" alt="Figure1" />
      </div>
      <div className="desc">
        △ 5일(금) 서울 잠실 소재 스테이지파이브(공동대표 이제욱ㆍ서상원)에서 ‘AIoT기반 유ㆍ아동 스마트 보건사업 MOU’를 진행했다.
        오른쪽부터 스테이지파이브 및 키위플러스 서상원 공동대표, 지아이바이옴 양보기 대표, 지아이비타 이길연 대표
      </div>
    </div>
    <br />
    <p>
      <br />
      전 세계에서 각광받고 있는 ‘마이크로바이옴(Microbiome)’을 IoT 기술을 이용하여 개인별로 진단, 맞춤형 제품으로 제작하는 길이
      열린다.
      <br />
    </p>
    <br />
    <p>
      이를 위해 ㈜스테이지파이브ㆍ㈜키위플러스ㆍ㈜지아이바이옴ㆍ㈜지아이비타 4개사(社)는 5일(금) 서울 잠실 소재
      스테이지파이브(공동대표 이제욱ㆍ서상원)에서 ‘AIoT기반 유ㆍ아동 스마트 보건사업 MOU’를 진행했다.
      <br />
    </p>
    <br />
    <p>
      업무협약을 통해 4개사는 생활습관이나 사회ㆍ환경적 요인 등에 있어 꾸준한 개선이 중요한 소아비만 및 알레르기 환아를 타깃으로
      맞춤형 마이크로바이옴을 진단하고, 제품화하기 위한 연구를 진행한다.
      <br />
    </p>
    <br />
    <p>
      ‘마이크로바이옴’이란 인체에 서식하는 ‘미생물(microbe)’과 ‘생태계(biome)’의 합성어로, 우리 몸에 사는 미생물과 그 유전정보의
      전체를 일컫는다. 최근 우리 몸 속에 서식하는 미생물이 인체에 미치는 영향력이 매우 크다는 연구 결과가 밝혀짐으로써 먹는 유산균
      뿐만 아니라 화장품 등 다양한 방면에서 화두가 되고 있다.
      <br />
    </p>
    <br />
    <p>
      4개사는 앞으로 지능형 사물인터넷(AIoT)을 기반으로 소아 비만 및 알레르기 환아를 위한 고객 맞춤형 마이크로바이옴 서비스를
      위해서 데이터 공동연구 및 제품 개발에 주력할 계획이다. 또한 이를 통해 유관 사업에 관련한 디바이스, 통신, 서비스 번들링
      개발에도 힘쓸 계획이다.
      <br />
    </p>
    <br />
    <p>
      맞춤형 마이크로바이옴 서비스 기획 및 제품 개발, 판매, 사업추진 등은 마이크로바이옴 기반 신약 개발 기업인 지아이바이옴과
      헬스데이터 테크 기업 지아이비타가 주도한다. 이들은 진단 디바이스를 비롯하여 개인 DB 분석, 맞춤 진단 및 유산균 제품 개발,
      생활 습관 지도 등 맞춤 서비스를 제공한다.
      <br />
    </p>
    <br />
    <p>
      스테이지파이브와 키위플러스는 통신 서비스 및 관제 인프라 제공, 웨어러블 디바이스 제공에 초점을 맞추어 협력함으로써
      효율적으로 AIoT 기반 사업에 힘을 실을 계획이다.
      <br />
    </p>
    <br />
    <p>
      <span className="bold">| 참여 기업 소개</span>
      <br />
      <br />
      스테이지파이브는 2017년 카카오 공동체에 편입돼 카카오 서비스를 기반으로 차세대 통신사업을 펼치는 기업이다. 2015년 국내최초
      안드로이드 OS를 탑재한 키즈 전용 디바이스 및 서비스 사업으로 시작하여, 디바이스와 소프트웨어 및 통신업계를 아우르고 있다.
      2020년 8월 ICT 규제샌드박스 사업을 개시하여 비대면 통신 플랫폼 ‘핀다이렉트샵’을 구축ㆍ운영하고 있다. 작년 10월에는
      키위플러스와 함께 미러북 ㆍAI 반려로봇 등 통신 기반 IoT 디바이스들을 선보이며 통신산업의 저변을 확대시키고 있다.
      <br />
      <br />
      키위플러스는 카카오리틀프렌즈폰 등 키즈 디바이스와 운영플랫폼을 개발하는 스마트 디바이스 전문 제조업체이다. 최근에는
      코스닥상장사인 주식회사 이더블유케이에 인수된 이후 중고폰 거래 서비스플랫폼과 IoT/AI 기반의 지능형 반려로봇 디바이스 사업을
      신규로 진행하며 스마트 비즈니스에 박차를 가하고 있다.
      <br />
      <br />
      지아이바이옴은 2018년 바이오 신약개발 기업 지아이이노베이션과 프로바이오틱스 제조 전문기업 메디오젠의 합작에 의해 설립된
      마이크로바이옴 신약 개발 회사다.
      <br />
      <br />
      개인 맞춤형 헬스케어 테크 기업인 지아이비타는 스마트워치 등 스마트 디바이스를 통해 수집된 데이터를 바탕으로 질병이 생기기
      전에 예방하는 인공지능 기반 자기주도형 헬스케어 솔루션을 개발하고 있다.
      <br />
    </p>
  </div>
)

const press8 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브 제휴협력실"
      writer="커뮤니케이션팀 pr@stagefive.com "
      date="2021년 3월 5일 즉시배포 가능"
      pageInfo="* 총 2매/ 첨부: 사진 1매"
      title="소아 비만, 알레르기 환아 위한 맞춤형 마이크로바이옴 서비스, IoT로 이뤄 낸다"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press8
